import { onLoad } from '../../../../wdh-foundation/wdh';
var $ = jQuery;
onLoad(function () {
  var $cookieWarning = $('.cookie-warning');
  var isNotEmpty = $cookieWarning.find('.component-content').children().length;
  if (isNotEmpty) {
    $cookieWarning.insertBefore('.wrapper');
    $cookieWarning.addClass('initialized component-ready');
    $('#wrapper').addClass('cookie-banner');
    $('.submit', $cookieWarning).click(function (e) {
      e.preventDefault();
      window.DGS.Cookies.getInstance().createCookie('cookie-warning', 1, 365);
      $('#wrapper').removeClass('cookie-banner');
      $cookieWarning.remove();
      window.DGS.Event.getInstance().trigger('cookie-warning-submit');
    });
  } else {
    $cookieWarning.remove();
  }
});