$(document).ready(function () {
  clearEmptyComponent();
  function clearEmptyComponent() {
    checkAccordion();
    checkBoxSpot();
    checkIframe();
    checkImageSpot();
    checkIntroBanner();
    checkRichText();
    checkTextImageSpot();
    checkVideo();
    markFirstRepeatingBackground();
  }

  // repeating-background
  function markFirstRepeatingBackground() {
    $("#content .grid-12 > .component, #footer .grid-12 > .component").each(function () {
      var $component = $(this);
      if (!$component.hasClass("repeating-background") && $component.next().css("background-color") === $component.css("background-color")) {
        $component.addClass("first-repeating-background");
      }
    });
  }
  // accordion
  function checkAccordion() {
    var accordionSpot = $(".content .component.accordion-spot");
    setTimeout(function () {
      return checkIfContentIsEmpty(accordionSpot);
    }, 0);
  }
  // box spot
  function checkBoxSpot() {
    var boxSpot = $(".content .component.box-spot");
    checkIfContentIsEmpty(boxSpot);
  }
  // iframe
  function checkIframe() {
    var iframe = $(".content .component.iframe");
    iframe.each(function () {
      var iframes = $(this).find("iframe");
      if (iframes.length) {
        var iframeSrc = $(this).find("iframe")[0].src;
        if (iframeSrc === "") {
          $(this).addClass("empty");
          $(this).css("height", "0");
        } else {
          $(this).remove("empty");
        }
      }
    });
  }
  // image spot
  function checkImageSpot() {
    var imageSpot = $(".content .component.image-spot");
    checkIfContentIsEmpty(imageSpot);
  }
  // intro banner
  function checkIntroBanner() {
    var introBanner = $(".content .component.intro-banner");
    introBanner.each(function () {
      var img = $(this).find(".intro-banner-image");
      var text = $(this).find(".intro-banner-text");
      var link = $(this).find(".intro-banner-link");
      var heroImg = $(this).hasClass("hero") ? $(this).find("img") : img.find("img");
      if (checkIfEmpty(img) && checkIfEmpty(text) && checkIfEmpty(link) && !checkIfExists(heroImg)) {
        $(this).addClass("empty");
      } else {
        $(this).remove("empty");
      }
    });
  }
  // rich text
  function checkRichText() {
    var richTexts = $(".content .component.rich-text");
    checkIfContentIsEmpty(richTexts);
  }
  // text image spot
  function checkTextImageSpot() {
    var textImageSpot = $(".content .component.text-image-spot");
    textImageSpot.each(function () {
      var imgContainer = $(this).find(".text-image-spot-image-container");
      var textContainer = $(this).find(".text-image-spot-text-container");
      if (!checkIfExists(imgContainer) || !checkIfExists(textContainer)) {
        $(this).addClass("empty");
      } else {
        $(this).remove("empty");
      }
    });
  }
  // video
  function checkVideo() {
    var video = $(".content .component.video-spot");
    video.each(function () {
      var videoCode = $(this).find(".embed-code");
      var videoImg = $(this).find(".video-spot-image");
      if (checkIfEmpty(videoCode) && checkIfEmpty(videoImg)) {
        $(this).addClass("empty");
      } else {
        $(this).remove("empty");
      }
    });
  }
  function checkIfContentIsEmpty(el) {
    el.each(function () {
      var checkedContent = $(this).find(".component-content");
      var check = checkIfEmpty(checkedContent);
      if (check) {
        $(this).addClass("empty");
      } else {
        $(this).remove("empty");
      }
    });
  }
  function checkIfEmpty(el) {
    return !$.trim(el.text());
  }
  function checkIfExists(el) {
    return el.length > 0;
  }
});