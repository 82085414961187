import { onLoad, getAll } from '../../../../../wdh-foundation/wdh';
onLoad(function () {
  // MOVE THE NEWSLETTER INPUT LABEL'S TEXT TO THE INPUT'S PLACEHOLDER INSTEAD
  var newsletterFormComponent = document.querySelector('.component[class*="blog"] + .component.form');
  var newsletterInput = newsletterFormComponent ? newsletterFormComponent.querySelector('input[type="text"]') : null;
  if (newsletterInput) {
    var label = newsletterFormComponent.querySelector('label.scfEmailLabel');
    var placeholderText = label.textContent;
    newsletterInput.setAttribute('placeholder', placeholderText);
  }

  // IF THERE ARE VALIDATION ITEMS ON LOAD, SHOW THE VALIDATION HINT INSTEAD
  var validationSummaryItems = newsletterFormComponent ? getAll('.scfValidationSummary li', newsletterFormComponent) : [];
  if (validationSummaryItems.length) {
    var validator = newsletterFormComponent ? newsletterFormComponent.querySelector('.scfValidator') : null;
    if (validator) {
      validator.style.display = 'inline';
    }
  }
});