import { onLoad } from '../../../../wdh-foundation/wdh';
require('../../../../wdh-foundation/dgs-sharing/dgs-sharing');
onLoad(function () {
  var socialSharingConfig = {
    layout: 'static',
    insertAfter: '#content .component.blog',
    serviceElementsHeaderClass: 'service-elements-header',
    serviceElementClass: 'service-element',
    shareWindowDimensions: 'width=626,height=436'
  };
  if ($('#social-sharing').length) {
    $('#social-sharing').sharingTools({
      layout: socialSharingConfig.layout,
      insertAfter: socialSharingConfig.insertAfter,
      serviceElementsHeaderClass: socialSharingConfig.serviceElementsHeaderClass,
      serviceElementClass: socialSharingConfig.serviceElementClass,
      shareWindowDimensions: socialSharingConfig.shareWindowDimensions
    });
    // if social media icons are present add identifying class to cookie-warning for manipulation purposes
  }
});