import { onLoad, getAll } from '../../../../../wdh-foundation/wdh';
onLoad(function () {
  var blogList = getAll('.component.blog-list #blog-list > li, .component.related-blogs .component-content > ul > li, .component.author-related-blogs .component-content > ul > li');
  if (blogList.length) {
    blogList.forEach(function (blogPost) {
      var dateNode = blogPost.querySelector('.news-date, .author-blog-date');
      dateNode.parentNode.insertBefore(dateNode, blogPost.querySelector('.news-text, .author-blog-text'));
      var aLinkNode = blogPost.querySelector('.news-link > a, .author-blog-link > a');
      if (aLinkNode) {
        var linkNode = aLinkNode.parentNode;
        linkNode.innerHTML = aLinkNode.innerHTML;
        aLinkNode.innerHTML = blogPost.innerHTML;
        blogPost.innerHTML = '';
        blogPost.appendChild(aLinkNode);
      }
    });
  }
});