import { onLoad } from "../../../../../wdh-foundation/wdh";
onLoad(function () {
  var wrapLinksInSpan = function wrapLinksInSpan() {
    var arrowLinks = document.querySelectorAll(".arrow-links.flex-spot .element__item__link > a");
    arrowLinks.forEach(function (link) {
      var text = link.textContent;
      var textCointainer = document.createElement("span");
      textCointainer.textContent = text;
      link.innerHTML = "";
      link.appendChild(textCointainer);
    });
  };
  wrapLinksInSpan();
});