var loadMore = function loadMore() {
  var blogList = document.querySelector(".bloglist");
  if (blogList) {
    var blogListElement = blogList.querySelectorAll("li");
    var loadMoreButton = document.createElement("button");
    var currentItems = 6;
    loadMoreButton.onclick = function (event) {
      event.preventDefault();
      for (var i = currentItems; i < currentItems + 6; i++) {
        if (blogListElement[i]) {
          blogListElement[i].style.display = 'block';
        }
      }
      currentItems += 6;

      // Load more button will be hidden after list fully loaded
      if (currentItems >= blogListElement.length) {
        event.target.style.display = 'none';
      }
    };
    loadMoreButton.classList.add("button");
    loadMoreButton.textContent = "Load more";
    var div = document.createElement("div");
    div.classList.add("load-more");
    blogList.appendChild(div);
    div.appendChild(loadMoreButton);
  }
};
(function () {
  loadMore();
})();