require('core-js');require('regenerator-runtime/runtime');import $ from "jquery";
require("./foundation/anchors");
window.jQuery = $;
window.$ = $;

// PROJECT
// eslint-disable-next-line global-require
if (document.querySelectorAll("#graduate-universe").length) require("./project/header-graduate-blog");
// eslint-disable-next-line global-require
else require("./project/header");
require("./project/circle");
require("./project/components");
require("./project/social-sharing");
require("./project/slider-stack");

// FOUNDATION

// FEATURE
require("./feature/component-image-spot/index");
require("./feature/component-iframe");
require("./feature/component-video-spot");
require("./feature/component-box-spot");
require("./feature/component-vertical-line-spot");
require("./feature/component-intro-banner/index");
require("./feature/component-rich-text/index");
require("./feature/component-search");
require("./feature/component-cookie-warning");
require("./feature/component-job-list-spot");
require("./feature/component-geo-ip-prompt");
require("./feature/component-text-image-spot");
require("./feature/graduate-blog");
require("./feature/component-flex-spot/index");
require("@demant/wdh-gdpr/lib/default.implementation.js");
    if (document.querySelector('.accordion-spot')) {
      import(/* webpackChunkName: "chunk-num-1", webpackPrefetch: true */'./feature/component-accordion-spot.js')
        .then(function (foo) { console.log('.accordion-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .accordion-spot'); console.log(error); });
    }
    if (document.querySelector('.resource-center:not(.resource-center-new)')) {
      import(/* webpackChunkName: "chunk-num-2", webpackPrefetch: true */'./feature/component-resource-center/legacy-resource-center/index.js')
        .then(function (foo) { console.log('.resource-center:not(.resource-center-new) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .resource-center:not(.resource-center-new)'); console.log(error); });
    }
    if (document.querySelector('.resource-center-new')) {
      import(/* webpackChunkName: "chunk-num-3", webpackPrefetch: true */'./feature/component-resource-center/wdh-resource-center/src/index.js')
        .then(function (foo) { console.log('.resource-center-new LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .resource-center-new'); console.log(error); });
    }