import { onLoad } from '../../../../../wdh-foundation/wdh';
import { TweenLite } from 'gsap';
onLoad(function () {
  var componentContent = document.querySelector('.component.comments .component-content');
  if (componentContent) {
    var joinConversationButton = document.querySelector('.joinconv-linkbutton');
    var topCommentsRow = document.querySelector('.topCommentsRow');
    componentContent.appendChild(topCommentsRow);
    joinConversationButton.addEventListener('click', function (evt) {
      evt.preventDefault();
      var anonCommentForm = document.querySelector('.anonymous-comment-form');
      if (anonCommentForm) {
        anonCommentForm.style.display = 'block';
      }
      var commentPost = document.querySelector('.comment-post');
      if (commentPost) {
        commentPost.style.display = 'block';
      }
    });
    TweenLite.to(joinConversationButton, 0.3, {
      opacity: 1
    });
  }
});