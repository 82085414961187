import { onLoad, onEvent } from '../../../../wdh-foundation/wdh';
var templates = Array.from(document.querySelectorAll('.iframe-gdpr-template'));
var templateC = 0;
templates.forEach(function (node) {
  templateC += 1;
  var div = node.innerHTML;
  div = div.replace('IframeTemplate', "IframeTemplate".concat(templateC));
  node.innerHTML = div;
});
var waitForEurolandIntegrationObject = function waitForEurolandIntegrationObject(callback) {
  var eurolandInterval = setInterval(function () {
    var EurolandToolIntegrationObject = window.EurolandToolIntegrationObject; // eslint-disable-line
    if (EurolandToolIntegrationObject) {
      clearInterval(eurolandInterval);
      callback(EurolandToolIntegrationObject);
    }
  }, 300);
};
onLoad(function () {
  var setupIframesWithEuroland = function setupIframesWithEuroland(EurolandToolIntegrationObject) {
    var iframes = Array.prototype.slice.call(document.querySelectorAll('.component.iframe iframe'));
    iframes.forEach(function (iframe) {
      /* Requires the following in TagsSettings->Tags Header in Sitecore
      <script type="text/javascript" src="//tools.euroland.com/tools/common/eurolandiframeautoheight/eurolandtoolsintegrationobject.js"></script> */
      EurolandToolIntegrationObject.set(iframe.id); // eslint-disable-line no-undef
    });
  };
  waitForEurolandIntegrationObject(function (EurolandToolIntegrationObject) {
    setupIframesWithEuroland(EurolandToolIntegrationObject);
  });
  onEvent('GDPR_IFRAME_ADDED', function (id) {
    waitForEurolandIntegrationObject(function (EurolandToolIntegrationObject) {
      EurolandToolIntegrationObject.set(id);
    });
  });
});