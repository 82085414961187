(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.search-result-spot').keypress(function (e) {
      var searchText = $('.search-result-spot input').val().trim();
      if (e.keyCode === 13 && searchText !== '') {
        e.preventDefault();
        window.location = "?q=".concat(searchText);
      }
    });
  });
})(jQuery);