(function ($) {
  window.DGS.GlobalSliderConfig = {
    slideInterval: 10000
  };

  // ONLOAD
  window.DGS.OnLoad.getInstance().register(function () {
    /* global slider */
    if ($('.component.slider-stack').length) {
      var slideOnMobile = true;
      var slideInterval = window.DGS.GlobalSliderConfig.slideInterval;
      var inEditMode = $('body').hasClass('mode-editing');
      var mobileMaxWidth = 700;
      var isMobileWidth;
      var setSliderHeights = function setSliderHeights() {
        $('.slider-stack-wrapper').each(function () {
          var maxHeight = Math.max.apply(null, $('.component.slider-stack', $(this)).map(function () {
            // console.log(this, $(this).height());
            return $(this).height();
          }).get());
          $(this).css('height', maxHeight);
          $('.slider-spinner', $(this)).hide();
          $('.slider-tabs', $(this)).css('opacity', 1);
        });
      };
      var setupSliderNavigation = function setupSliderNavigation($sliderStackWrapper) {
        var showOnMobile = slideOnMobile ? 'show-on-mobile' : '';
        var $slides = $sliderStackWrapper.find('.component.slider-stack');
        var $tabs;
        var tabsHtml = "<div class='slider-tabs'><div class='tabs-content'>";
        var arrowLeft = "<div class='slider-arrow-left ".concat(showOnMobile, "'></div>");
        var arrowRight = "<div class='slider-arrow-right ".concat(showOnMobile, "'></div>");
        var spinner = "<div class='slider-spinner'>Loading&#8230;</div>";

        // build up slider tabs
        if (!$sliderStackWrapper.find('.slider-tabs').length) {
          $slides.each(function () {
            tabsHtml += "<div class='slider-tab'></div>";
          });
          tabsHtml += '</div></div>';
          $sliderStackWrapper.append(tabsHtml);
          $tabs = $sliderStackWrapper.find('.slider-tabs');
        }

        // build up slider arrows
        if (!$sliderStackWrapper.find('.slider-arrow-left').length) $sliderStackWrapper.append(arrowLeft);
        if (!$sliderStackWrapper.find('.slider-arrow-right').length) $sliderStackWrapper.append(arrowRight);

        // spinner
        if (!$sliderStackWrapper.find('.slider-spinner').length) $sliderStackWrapper.append(spinner);
      };
      var cleanupSliderNavigation = function cleanupSliderNavigation($sliderStackWrapper) {
        $sliderStackWrapper.find('.slider-tabs').remove();
        $sliderStackWrapper.find('.slider-arrow-left').remove();
        $sliderStackWrapper.find('.slider-arrow-right').remove();
        $sliderStackWrapper.find('.slider-spinner').remove();
      };
      var buildSlider = function buildSlider() {
        $('.component.slider-stack').css('height', 'auto');
        $('.slider-stack-wrapper').each(function () {
          var currentIndex = 0;
          var $slides = $(this).find('.component.slider-stack');
          var $tabs = $(this).find('.slider-tabs');
          var timeInterval = -1;
          var rotateSlides = function rotateSlides(goback) {
            if (goback) {
              // move backward
              if (currentIndex === 1) currentIndex = $slides.length;else currentIndex--;
            } else {
              // move forward
              if (currentIndex === $slides.length) currentIndex = 1;else currentIndex += 1;
            }
            $slides.filter('.active-slide').addClass('going-out').removeClass('active-slide');
            $slides.filter('.slide-backwards').removeClass('slide-backwards');
            $tabs.find('.slider-tab.active-tab').removeClass('active-tab');
            $slides.eq(currentIndex - 1).removeClass('going-out');
            setTimeout(function () {
              $slides.eq(currentIndex - 1).addClass('active-slide');
            }, 100);
            if (goback) $slides.eq(currentIndex - 1).addClass('slide-backwards');
            $tabs.find(".slider-tab:nth-child(".concat(currentIndex, ")")).addClass('active-tab');
          };
          $('.slider-arrow-left').click(function () {
            window.clearInterval(timeInterval);
            rotateSlides(true);
          });
          $('.slider-arrow-right').click(function () {
            window.clearInterval(timeInterval);
            rotateSlides();
          });
          $('.slider-tab').click(function () {
            window.clearInterval(timeInterval);
            var tabIndex = $tabs.find('.slider-tab').index($(this));
            if (tabIndex + 1 < currentIndex) {
              currentIndex = tabIndex + 2;
              rotateSlides(true);
            } else {
              currentIndex = tabIndex;
              rotateSlides();
            }
          });
          $('.component.slider-stack .component-content', $(this)).on('swipeleft', function (event) {
            window.clearInterval(timeInterval);
            rotateSlides();
          });
          $('.component.slider-stack .component-content', $(this)).on('swiperight', function (event) {
            window.clearInterval(timeInterval);
            rotateSlides(true);
          });
          setSliderHeights();
          currentIndex = 0;
          window.clearInterval(timeInterval);

          // mark first as active
          rotateSlides();

          // start interval rotation
          timeInterval = setInterval(rotateSlides, slideInterval);
          $(this).hover(function () {
            // pause rotation on mouse over
            clearInterval(timeInterval);
          }, function () {
            // restart rotation on mouse leave
            timeInterval = setInterval(rotateSlides, slideInterval);
          });
        });
      };
      var setupGroup = function setupGroup($group) {
        var isMobile = isMobileWidth || $group.filter('.content-overflow').length;
        if (isMobile && !slideOnMobile) {
          if ($group.parent().is('.slider-stack-wrapper')) {
            cleanupSliderNavigation($group.parent());
            $group.unwrap();
          }
        } else if (!$group.parents('.slider-stack-wrapper').length && !inEditMode) {
          $group.wrapAll('<div class="slider-stack-wrapper " />');
        } else if (inEditMode) {
          $group.each(function (index) {
            $(this).addClass('slide-index');
            $(this).attr('data-index', "slide :".concat(index + 1));
          });
        }
        if (!isMobile || slideOnMobile) {
          setupSliderNavigation($group.parent());
        }
      };
      window.DGS.Resizer.getInstance().register(function () {
        var $group = null;
        var slideOnMobileClass = 'slide-on-mobile';
        isMobileWidth = window.matchMedia("(max-width: ".concat(mobileMaxWidth, "px)")).matches;
        $('.component').each(function () {
          var $this = $(this);
          if ($this.hasClass('slider-stack')) {
            // Checking only if false; if one in the group is set to true, it will apply to the entire stack
            if (!slideOnMobile) {
              slideOnMobile = $this.hasClass(slideOnMobileClass);
            }
            if (!$group) $group = $this;else $group = $.merge($group, $this);
          } else {
            if ($group) {
              setupGroup($group);
            }
            $group = null;
          }
        });
        if ($group) {
          setupGroup($group);
        }
        if (slideOnMobile) {
          $('.slider-stack-wrapper').addClass(slideOnMobileClass);
        }

        // show spinner and reset slider height
        $('.slider-stack-wrapper .active-slide').removeClass('active-slide');
        $('.slider-stack-wrapper .slider-spinner').show();
        $('.slider-stack-wrapper .slider-tabs').css('opacity', 0);
      });
      window.DGS.Event.getInstance().on(window.DGS.Events.RESIZE_DONE, function () {
        buildSlider();
      });
    }
    /* global slider - end */
  });
})(jQuery);